.cv img{
    width:70%;
    height: 100%;
    margin-bottom:20px;
}
.cv{
    margin-top:190px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    width: 100%;
}
.cv h1{
    margin-bottom:45px;
    font-weight: 800;
}
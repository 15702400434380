.about {
background-color: #191D37;
height: max-content!important;
margin-bottom: 5px;
}
.about .title h1 {
  color: white;
  text-align: center;
}
.about-her {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  color: aliceblue;
  padding: 20px 0px;
}
.about-her h2 {
  color: #ffffff;
  font-weight: 900;
}
.propert {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
}
.propp {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 20px;
  gap: 10px;
  color: black;
  border-radius: 30px;
}
.propp .icon {
  color: rgb(0, 129, 242);
  font-size: 30px;
}
.propp:hover {
  transform: scale(1.05);
  transition: 0.4s;
  cursor: pointer;
}
.hashtag {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.hashtag img {
  width: 30%;
}
.about-img-middle .button img{
width: 100% !important;;
margin: auto!important  ;
text-align: center;
}
.about-img-middle img{
  width: 100%;
}

.svg-animation text {
  color: rgb(255, 0, 0) !important;
  background-color: #ffffff !important;
  font-size: 70px !important;
}
